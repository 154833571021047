.news-item {
  &__date {
    @include breakpoint(phone-low) {
      font-size: 12px;
    }
  }

  &__title {
    word-break: break-all;
    @include breakpoint(phone-low) {
      font-size: 14px;
    }
  }

  &__image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 25%;

    & > img {
      width: 100%;
    }
  }
}
