input[type='radio'].form-check-input {
  position: relative;
  visibility: hidden;

  ~ label {
    &:before {
      display: inline-block;
      margin-right: 5px;
      content: '';
      border-radius: 100%;
      height: 15px;
      width: 15px;
      border: 4.5px solid $secondaryLightest;
    }
  }

  &:checked {
    ~ label {
      &:before {
        border-color: $primary;
      }
    }
  }
}

.brand {
  padding: 0;
  margin: 6px 0;
  input.form-check-input[type='radio'] {
    display: none;
    ~ label {
      position: relative;
      &:before {
        position: absolute;
        border: none;
        content: ' ';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
        z-index: 2;
      }

      &.unselected:before {
        background-color: rgba(0, 0, 0, 0.75);
      }

      img {
        position: relative;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.store-card {
  padding: 10px 30px 10px 10px;
  border-bottom: 1px solid $secondaryLightest;
  position: relative;
  &--name {
    font-weight: bold;
  }
  &--address {
    margin-left: 18px;
  }
  input.form-check-input[type='radio'] {
    ~ label {
      &:before {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:checked {
      ~ label {
        &:before {
          border-color: $primary;
        }
      }
    }
  }
}
