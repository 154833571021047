.home {
  .card {
    position: relative;
    border-radius: 0;
    margin-top: -1rem;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40.5%,
        rgba(255, 255, 255, 0.35) 41%,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 0) 100%
      );
      background-size: 400% 100%;
      animation: bggradient 3s ease infinite;
      pointer-events: none;

      @keyframes bggradient {
        0% {
          background-position: 0% 50%;
        }
        100% {
          background-position: 100% 50%;
        }
      }
    }

    & * {
      color: $white;
    }

    &__balance {
      font-size: 40px;
      font-weight: bold;

      &-unit {
        font-weight: normal;
        font-size: 24px;
      }
    }

    &__barcode {
      width: 90%;
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      z-index: 11;
      left: 0;
      right: 5px;
      bottom: 7%;
      border-radius: 8px;
      display: table;
    }
  }

  .barcodeValidate {
    color: $secondary;
  }
}
.modal {
  .card-points {
    font-size: 32px;
  }
}

.refresh-barcode-container {
  width: 120px;
  padding-left: 16px;
  padding-right: 8px;
  align-items: center;
  justify-content: center;
  .refresh-btn {
    margin-top: 4px;
    background-color: #ffffff;
    color: $primary !important;
    border: none;
  }
}
