input[type='text'] {
  padding: 8px;
  &:read-only {
    background-color: transparent;
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.form-label {
  font-weight: bold;
}

.label-light {
  .form-label {
    color: $secondaryLight;
  }
}

.text-input-group {
  &--clear {
    .input-group-text {
      padding-right: 0;
      background: none;
      border: none;
    }

    div {
      width: 75%;
    }
  }
}

input[type='password'],
input[type='number'] {
  padding: 8px;
}
