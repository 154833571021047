.menu {
  &__items {
    background-color: $white;
    border-bottom: 1px solid $secondaryLightest;
    padding: 15px 20px;

    &:first-child {
      border-top: 1px solid $secondaryLightest;
    }
  }
}
