// https://getbootstrap.jp/docs/5.0/utilities/spacing/#negative-margin
$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap';

@import 'common/variable';

@import 'page/Top';
@import 'page/CardHome';
@import 'page/CardHistory';
@import 'page/CouponList';
@import 'page/StoreSearch';
@import 'page/MyPage';
@import 'page/Error';

@import 'common/AlertMessage';
@import 'common/Button';
@import 'common/CheckboxInput';
@import 'common/CouponItem';
@import 'common/Containers';
@import 'common/Footer';
@import 'common/FormReviewItem';
@import 'common/FullBanner';
@import 'common/Layout';
@import 'common/Menu';
@import 'common/Modal';
@import 'common/Misc';
@import 'common/NewsItem';
@import 'common/Snackbar';
@import 'common/RadioInput';
@import 'common/SelectInput';
@import 'common/Text';
@import 'common/TextInput';
@import 'common/Loader';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

// クローバル
#root,
.app-wrapper {
  height: 100%;
}

* {
  color: $dark;
  font-size: 14px;
  font-family: 'Noto Sans JP', sans-serif;
}

html {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  background-color: $grey;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

a {
  color: unset;
  text-decoration: none;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
}
