.history {
  .card-info {
    &__balance {
      font-size: 40px;

      @include breakpoint(phone-low) {
        font-size: 38px;
      }

      &-unit {
        font-size: 24px;
      }
    }

    &__charge {
      border: 1px solid $primary;
      border-radius: 4px;
      color: $primary;
      font-size: 13px;

      @include breakpoint(phone-low) {
        font-size: 10px;
      }
    }

    .info {
      &__label,
      &__date {
        color: $secondaryLight;
      }

      &__value {
        font-size: 18px;
      }

      &__date {
        font-size: 11px;
      }
    }

    .ver-separator {
      &:after {
        height: 80px;
        bottom: 0;
        top: unset;
      }
    }
  }

  .history-item {
    &__badge {
      font-size: 11px;
      color: $secondary;
      background-color: $secondaryLightest;
      border-radius: 3px;
    }

    &__date {
      color: $secondaryLight;
    }

    &__store {
      width: 65%;
    }

    &__amount {
      &.add {
        span {
          color: $historyAdd;
        }
      }
      &.remove {
        span {
          color: $historyRemove;
        }
      }
    }

    &__balance {
      font-size: 11px;
      color: $lighterDark;
    }

    &:not(:only-child):not(:last-child) {
      border-bottom: 1px solid $secondaryLightest;
    }
  }
}
