/**
  * Color
  */
$primary: #e60121;
$primaryLight: #fce6e6;

$secondary: #333;
$secondaryLight: #707070;
$secondaryLighter: #9a9a9a;
$secondaryLightest: #e2e2e2;
$secondaryLightestLowOpacity: #e2e2e27c;

$historyAdd: #015de6;
$historyRemove: #e81a1a;

$disabledBg: #dadada;
$disabledBorder: #808080;
$disabledText: #757575;

$white: #fff;
$dark: #191919;
$lighterDark: #555555;
$grey: #f2f2f2;

/**
  * Breakpoints
  */
$media: (
  phone-low: 320px,
  phone: 375px,
  phone-high: 420px,
  tablet: 768px,
  tablet-high: 1024px
);

@mixin breakpoint($keys...) {
  @each $key in $keys {
    @media (max-width: map-get($media, $key)) {
      @content;
    }
  }
}
