.modal {
  &--custom {
    .modal-dialog {
      margin: auto;
      width: 85%;

      @include breakpoint(phone-low) {
        width: 90%;
      }
    }

    .modal-content {
      border-radius: 8px;
      padding: 8px 0;
    }
  }

  &--custom-bottom {
    .modal-dialog {
      position: fixed;
      top: auto;
      right: auto;
      left: auto;
      bottom: 0;
      margin: auto;
      width: 100%;
      transition: all 0.3s ease-out;
      opacity: 0;

      @include breakpoint(phone-low) {
        width: 90%;
      }
    }

    .modal-content {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 8px 0;
    }

    &.fade {
      .modal-dialog {
        transition: all 0.3s ease-out;
        opacity: 1;
        transform: translateX(-50%);
        left: 50%;
      }
    }
    &.show {
      .modal-dialog {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }

  .card {
    &__barcode {
      width: 90%;
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      z-index: 11;
      left: 0;
      right: 5px;
      bottom: 7%;
      border-radius: 8px;
      display: table;
    }
  }
}
