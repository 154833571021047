.section-cont {
  border: 1px solid $secondaryLightest;
  border-radius: 4px;
  background-color: $white;

  &--rounded-1 {
    border-radius: 4px;
  }

  &--rounded-2 {
    border-radius: 8px;
  }

  &--shadow {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.08);
  }
}
