.full-height {
  min-height: 100vh;
  position: relative;

  .bottom-fix {
    margin-top: auto;
    right: 0;
  }
}

.row {
  &--no-mt {
    margin-top: -1rem;
  }

  .full-width {
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
}

.with-footer {
  overflow: auto;
  flex-grow: 1;
}
