.custom-nav-tab {
  justify-content: center;
  border: 1px solid #9a9a9a;
  border-radius: 8px;
  overflow: hidden;

  & * {
    color: $secondaryLight;
  }

  .nav-item {
    flex-grow: 1;
    flex-basis: 0;

    .nav-link {
      width: 100%;

      &.active {
        background-color: $primary;
        border-color: $primary;
        border-radius: unset;
        color: $white;
      }
    }
  }
}
