.text {
  // sizes
  &--sm {
    font-size: 12px;
  }

  &--md {
    font-size: 16px;
  }

  &--lg {
    font-size: 18px;
  }

  &--xlg {
    font-size: 24px;
  }

  &--big {
    font-size: 40px;
  }
  // colors
  &--dark {
    color: $dark;
  }

  &--primary {
    color: $primary;
  }

  &--secondary {
    color: $secondary;
  }

  &--secondary-light {
    color: $secondaryLight;
  }
}
