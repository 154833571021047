.btn {
  &--custom {
    border-radius: 5px;
    font-size: 16px;
  }

  &--primary {
    &,
    &:active,
    &:focus,
    &:hover {
      background-color: $primary;
      color: $white;
      border: none;
    }
  }

  &--primary-light {
    &,
    &:active,
    &:focus,
    &:hover {
      background-color: $primaryLight;
      color: $primary;
      border: none;
    }
  }

  &--primary-outline {
    &,
    &:active,
    &:focus,
    &:hover {
      color: $primary;
      border: 1px solid $primary;
    }
  }

  &--primary-link {
    &,
    &:active,
    &:focus,
    &:hover {
      color: $primary;
      border: none;
    }
  }

  &--secondary-light {
    &,
    &:active,
    &:focus,
    &:hover {
      background-color: $grey;
      color: $secondaryLight;
      border: 1px solid $secondaryLightest;
    }
  }

  &--secondary-outline {
    &,
    &:active,
    &:focus,
    &:hover {
      background-color: unset;
      color: $secondary;
      border: 1px solid $secondaryLighter;
    }
  }

  &--secondary-link {
    &,
    &:active,
    &:focus,
    &:hover {
      color: $secondary;
      border: none;
    }
  }

  &--big {
    font-size: 16px;
  }

  &.disabled {
    background-color: $disabledBg;
    color: $disabledText;
    border-color: $disabledBorder;
    pointer-events: none;
  }
}
