.error {
  &-500 {
    background-color: $white;

    & > .align-self-center {
      align-items: center;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      padding: 0 29.5px;
      width: 100%;
    }

    &__close-icon {
      border: 1px solid #e53e3e;
      border-radius: 50%;
      padding: 16px;

      & > svg {
        aspect-ratio: 1 / 1;
        fill: #e53e3e;
        width: 32px;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;
    }

    &__title,
    &__text {
      color: $secondary;
      margin: 16px 0 0;
      text-align: center;
    }

    &__text {
      font-size: 16px;
      line-height: 1.5;
    }

    &__return {
      background: linear-gradient(to right, #e71a0f, #c90000);
      border-radius: 24px;
      color: $white;
      font-size: 16px;
      font-weight: 700;
      margin-top: 48px;
      padding: 12px 0;
      text-align: center;
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:active,
      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        color: $white;
        transform: scale(0.95);
      }
    }
  }
}
