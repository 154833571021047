.ver-separator {
  position: relative;

  &:after {
    content: '';
    height: 60%;
    position: absolute;
    width: 1px;
    right: 0;
    top: 20%;
    background-color: $secondaryLightest;
  }
}
