.coupon-item {
  img {
    width: 25%;
  }

  &__store,
  &__date {
    @include breakpoint(phone-low) {
      font-size: 12px;
    }
  }

  &__title {
    @include breakpoint(phone-low) {
      font-size: 14px;
    }
  }

  &--inactive {
    background-color: $secondaryLightest !important;
  }
}
