input[type='checkbox'] {
  accent-color: $primary !important;
  &:hover {
    box-shadow: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}

.form-check-input {
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
}
