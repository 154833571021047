.error-message {
  font-size: 0.9rem;

  &.bg {
    &--error {
      background-color: $primaryLight;
    }
  }

  &:first-child {
    padding-top: 1rem;
  }
}
