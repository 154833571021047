.line-through {
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }

  &:after {
    background-color: $secondaryLightest;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 0;
  }
}
