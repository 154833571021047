.snackbar {
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 25px;
  position: absolute;
  text-align: center;
  width: max-content;
  margin: 0 auto;
  top: 10px;
  left: 0;
  right: 0;

  * {
    color: $white;
  }

  &--fadeIn {
    opacity: 0.85;
    -webkit-transition: opacity 0.85s;
    -moz-transition: opacity 0.85s;
    transition: opacity 0.85s;
    visibility: visible;
  }

  &--fadeOut {
    opacity: 0;
    -webkit-transition: opacity 0.85s;
    -moz-transition: opacity 0.85s;
    transition: opacity 0.85s;
    visibility: hidden;
  }
}
