.search {
  &__badge {
    background-color: $secondaryLight;
    border-radius: 10px;
    color: $white;
    padding: 0 8px;
  }

  .btn {
    &--custom {
      font-size: 13px;
      padding: 0.65rem !important;
    }
  }

  .accordion {
    &--custom {
      margin: 0 calc(var(--bs-gutter-x) * -0.7);

      .accordion-header {
        border-bottom: 1px solid $secondaryLightest;
      }

      .accordion-item {
        background-color: $grey;
        border: none;

        &:first-child {
          .accordion-header {
            border-top: 1px solid $secondaryLightest;
          }
        }
      }

      .accordion-button {
        &:focus {
          box-shadow: none;
        }

        &:not(.collapsed) {
          background-color: #fff;
          box-shadow: none;
        }
      }

      &__child {
        border-bottom: 1px solid $secondaryLightestLowOpacity !important;

        .accordion-header {
          border: none !important;
        }

        .accordion-button {
          background-color: $grey;

          &:not(.collapsed) {
            background-color: unset;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.store {
  &-card {
    &__top {
      padding-right: 60px;
    }
    & .line-account {
      &-icon {
        width: 50px;
      }
    }
  }
}
